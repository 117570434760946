import React, { useEffect, useState } from 'react';
import { WindowDimensionContext } from './WindowDimensionContext';

type WindowDimensionContextWrapperProps = {
  children: React.ReactNode;
};

const WindowDimensionContextWrapper = ({
  children,
}: WindowDimensionContextWrapperProps) => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const setWindowSize = () =>
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });

  useEffect(() => {
    window.addEventListener('resize', setWindowSize);
    return () => window.removeEventListener('resize', setWindowSize);
  }, []);

  return (
    <WindowDimensionContext.Provider
      value={{
        windowDimensions,
        setWindowSize,
      }}
    >
      {children}
    </WindowDimensionContext.Provider>
  );
};

export default WindowDimensionContextWrapper;
