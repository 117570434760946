import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  redirectPath: string;
  label: string;
};

const CreateLessonBtn = ({ redirectPath, label }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="createLesson_btn">
      <button onClick={() => navigate(redirectPath)}>
        <i className="fa-solid fa-plus"></i> {label}
      </button>
    </div>
  );
};

export default CreateLessonBtn;
