import { API_ENDPOINTS } from '../api/API_ENDPOINTS';
import { newBackend, oldBackend } from '../api/request';
import { createEnCodedURL } from '../lib/Helper/helper';
import { CreateChapterModel } from '../models/ChapterModel';

const urlForGetChaptersForDropdown: string =
  API_ENDPOINTS.CHAPTERS.GET_CHAPTERS_DROPDOWN;

const urlForCreateChapter: string = API_ENDPOINTS.CHAPTERS.CREATE_CHAPTER;
const urlForGetChapter: string = API_ENDPOINTS.CHAPTERS.GET_CHAPTERS;
const urlForUpdateChapter: string = API_ENDPOINTS.CHAPTERS.PUT_CHAPTERS;
const urlForUpdateChaptersNumber: string =
  API_ENDPOINTS.CHAPTERS.UPDATE_CHAPTERS_NUMBER;
const urlForUpdateChapterStatus: string =
  API_ENDPOINTS.CHAPTERS.UPDATE_CHAPTERS_STATUS;
const urlForGetChaptersForSubject: string =
  API_ENDPOINTS.CHAPTERS.GET_CHAPTER_FOR_SUBJECT;
const urlForGetChaptersList: string = API_ENDPOINTS.CHAPTERS.GET_CHAPTER_LIST;

export const getChaptersForDropdown = async () =>
  await oldBackend({
    method: 'GET',
    url: urlForGetChaptersForDropdown,
  });

export const createChapter = async (chapterObj: CreateChapterModel) =>
  await oldBackend({
    method: 'POST',
    url: urlForCreateChapter,
    data: chapterObj,
  });

export const getChaptersDetail = async (chapterID?: string) =>
  await oldBackend({
    method: 'GET',
    url: chapterID
      ? `${urlForGetChapter}?_id=${chapterID}`
      : `${urlForGetChapter}`,
  });

export const getChaptersByID = async (chapterID?: string) => {
  const proj = createEnCodedURL([
    {
      path: 'grade',
      select: 'grade',
    },
    {
      path: 'subject',
      select: 'Subject_name',
    },
  ]);

  return await oldBackend({
    method: 'GET',
    url: `${urlForGetChapter}?_id=${chapterID}&populate=${proj}`,
  });
};

export const updateChapter = async (chapterObj: CreateChapterModel) =>
  await oldBackend({
    method: 'PUT',
    url: `${urlForUpdateChapter}/${chapterObj?._id}`,
    data: chapterObj,
  });

export const updateChapterSequence = async (chapterIDs: Array<string>) =>
  await newBackend({
    method: 'PUT',
    url: urlForUpdateChaptersNumber,
    data: chapterIDs,
  });

export const updateChapterStatus = async (
  chapterID: string,
  isActive: boolean,
) =>
  await newBackend({
    method: 'PUT',
    url: `${urlForUpdateChapterStatus}/${chapterID}`,
    data: { isActive },
  });

export const getChaptersForSubject = async (subjectID: string) =>
  await newBackend({
    method: 'GET',
    url: `${urlForGetChaptersForSubject}/${subjectID}`,
  });

export const getChaptersList = async () =>
  await newBackend({
    method: 'GET',
    url: urlForGetChaptersList,
  });
