import { API_ENDPOINTS } from '../api/API_ENDPOINTS';
import { newBackend, oldBackend } from '../api/request';

const urlForGetGradesForDropdown: string =
  API_ENDPOINTS.GRADES.GET_GRADES_DROPDOWN;

export const getGradesForDropdown = async () =>
  await oldBackend({
    method: 'GET',
    url: urlForGetGradesForDropdown,
  });

export const getGradesList = async () =>
  await newBackend({
    method: 'GET',
    url: '/grade',
  });
