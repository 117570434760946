import * as React from 'react';
import AuthContextWrapper from './AuthContext/AuthContextWrapper';
import CommonContextWrapper from './CommonContext/CommonContextWrapper';

type ContextWrapperProps = {
  children: React.ReactNode;
};

const ContextWrapper = ({ children }: ContextWrapperProps) => {
  return (
    <AuthContextWrapper>
      <CommonContextWrapper>{children}</CommonContextWrapper>
    </AuthContextWrapper>
  );
};

export default ContextWrapper;
