export const API_ENDPOINTS = {
  USERS: {
    TEACHER_LOGIN: '/user/login',
    GET_TEACHER_DETAILS: '/user',
    GET_SUPER_ADMIN_DETAILS: '/superAdmin',
    SUPER_ADMIN_LOGIN: '/superAdmin/login',
    USER_DELETE: '/user/delete/data',
    GET_STUDENT_BY_REFERRAL_CODE: '/user/referral',
    GET_STUDENT_BY_ID: '/user',
    USER_UPDATE: '/user',
  },
  GRADES: {
    GET_GRADES_DROPDOWN: '/grades/dropdown',
  },
  SUBJECTS: {
    GET_SUBJECTS_DROPDOWN: '/subject/dropdown',
    TEACHER_SUBJECTS: '/teacher/subjects/assign',
    GET_ALL_SUBJECT: '/subject',
  },
  CHAPTERS: {
    GET_CHAPTERS_DROPDOWN: '/chapter/dropdown',
    CREATE_CHAPTER: '/chapter',
    GET_CHAPTERS: '/chapter',
    PUT_CHAPTERS: '/chapter',
    UPDATE_CHAPTERS_NUMBER: '/chapter/manage/sequence',
    UPDATE_CHAPTERS_STATUS: '/chapter/status',
    GET_CHAPTER_FOR_SUBJECT: '/chapter/subject',
    GET_CHAPTER_LIST: '/chapter/list',
  },
  LESSONS: {
    SAVE_LESSON: '/lessons',
    GET_LESSONS: '/lessons',
    UPDATE_LESSON: '/lessons',
    GET_LESSON_FOR_DROPDOWN: '/lessons/dropdown',
    UPDATE_LESSON_STATUS: '/lessons/status',
    GET_LESSON_BY_CHAPTER: '/lessons/count',
    GET_LESSON_PER_CHAPTER_PER_SUBJECT_DETAILED: '/lessons/subject/count',
    GET_LESSON_ORDERING: '/lessons/ordering',
    UPDATE_LESSON_ORDERING: '/lessons/manage/sequence',
    GET_LESSON_WITH_UNI_CODES: '/lessons/uniCodes',
  },
  IMAGE: {
    SAVE_IMAGE: '/filesupload/file-upload',
  },
  QUESTION: {
    SAVE_MULTIPLE_QUESTION: '/question/multiple-choice',
    GET_QUESTION: '/question',
    UPDATE_MULTIPLE_QUESTION: '/question/multiple-choice',
    GET_QUESTION_FOR_DROPDOWN: '/question/dropdown',
  },
  VIMEO: {
    UPLOAD: '/vimeo/upload',
  },
  CASTR: {
    UPLOAD: '/castr/upload',
  },
  FEEDBACK: {
    GET_FEEDBACK: '/feedback',
  },
  REGISTERED_COURSE: {
    GET_STUDENTS_PER_SUBJECT: '/courseregistration/students',
    UPDATE_USER_REGISTRATION: '/courseregistration/ban',
    GET_PREMIUM_STUDENTS_BY_GRADE: '/courseregistration/premium/grade',
  },
  DINAR_PACK: {
    DINAR_PACK: '/dinars',
  },
  CODES: {
    CODES: '/codes',
  },
  NOTIFICATION: {
    POST_NOTIFICATION: '/notifications',
  },
  GENERAL_SETTINGS: {
    GENERAL_SETTINGS: '/settings',
  },
  JOURNEY: {
    JOURNEY_SCREEN_NAME: '/journeys/screen',
  },
  REPORTS: {
    NEW_USER: '/reports/new/users',
    USERS_STATS: '/reports/user/stats',
    USERS_JOURNEY_DATA: '/reports/user/journey/data',
    USERS_WITH_STATUS: '/reports/users/status',
    USERS_GRADE_PURCHASE_LOGS: '/reports/grade/purchase/logs',
    USERS_SUBJECT_PURCHASE_LOGS: '/reports/subject/purchase/logs',
    USERS_CHAPTER_PURCHASE_LOGS: '/reports/chapter/purchase/logs',
    USERS_LESSON_PURCHASE_LOGS: '/reports/lesson/purchase/logs',
    MOST_AND_LEAST_PURCHASE_GRADES: '/reports/most/purchase/grades',
    MOST_AND_LEAST_PURCHASE_SUBJECT: '/reports/most/purchase/subjects',
    MOST_AND_LEAST_PURCHASE_CHAPTERS: '/reports/most/purchase/chapters',
    MOST_AND_LEAST_PURCHASE_LESSONS: '/reports/most/purchase/lessons',
    PREMIUM_USERS_WALLETS: '/reports/premium/users/wallets',
    PREMIUM_USERS_CREDIT_LOGS: '/reports/premium/users/credit',
    PREMIUM_USERS_DEBIT_LOGS: '/reports/premium/users/debit',
    PREMIUM_USERS_DINAR_CREDIT_LOGS: '/reports/users/credits/dinars',
    PREMIUM_USERS_LIST: '/reports/premium/user/list',
  },
  PURCHASES: {
    GET_USER_PURCHASE_LOGS: '/purchases/details',
    DELETE_USER_SUBSCRIPTION: '/purchases/delete',
  },
};
