import { API_ENDPOINTS } from '../api/API_ENDPOINTS';
import { newBackend, oldBackend } from '../api/request';
import { getValueFromLocalStorage } from '../lib/Helper/helper';

const urlForTeacherLogin: string = API_ENDPOINTS.USERS.TEACHER_LOGIN;
const urlForGetTeacherDetails: string = API_ENDPOINTS.USERS.GET_TEACHER_DETAILS;
const urlForGetSuperAdminDetails: string =
  API_ENDPOINTS.USERS.GET_SUPER_ADMIN_DETAILS;
const urlForSuperAdminLogin: string = API_ENDPOINTS.USERS.SUPER_ADMIN_LOGIN;
const urlForUserDelete: string = API_ENDPOINTS.USERS.USER_DELETE;
const urlForGetUserByReferral: string =
  API_ENDPOINTS.USERS.GET_STUDENT_BY_REFERRAL_CODE;
const urlForGetUserById: string = API_ENDPOINTS.USERS.GET_STUDENT_BY_ID;
const urlForUserUpdate: string = API_ENDPOINTS.USERS.USER_UPDATE;

interface LoginCredentials {
  email: string;
  password: string;
}

export const loginTeacher = async (loginCredentials: LoginCredentials) =>
  await oldBackend({
    method: 'POST',
    url: urlForTeacherLogin,
    data: loginCredentials,
  });

export const getTeacherDetails = async () =>
  await oldBackend({
    method: 'GET',
    url: `${urlForGetTeacherDetails}?_id=${getValueFromLocalStorage('userID')}`,
  });

export const getSuperAdminDetails = async () =>
  await oldBackend({
    method: 'GET',
    url: `${urlForGetSuperAdminDetails}?_id=${getValueFromLocalStorage(
      'userID',
    )}`,
  });

export const loginSuperAdmin = async (loginCredentials: LoginCredentials) =>
  await newBackend({
    method: 'PUT',
    url: urlForSuperAdminLogin,
    data: loginCredentials,
  });

export const userDeleteAPI = async (deleteValue: string) =>
  await oldBackend({
    method: 'PUT',
    url: `${urlForUserDelete}/${deleteValue}`,
    data: {},
  });

export const getUserByReferral = async (referral: string) =>
  await newBackend({
    method: 'GET',
    url: `${urlForGetUserByReferral}/${referral}`,
  });

export const getUserById = async (userId: string) =>
  await newBackend({
    method: 'GET',
    url: `${urlForGetUserById}/${userId}`,
  });

export const updateUser = async (userId: string, data: object) =>
  await newBackend({
    method: 'PUT',
    url: `${urlForUserUpdate}/${userId}`,
    data,
  });
