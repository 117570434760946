import { createContext } from 'react';

export interface CommonCtx {
  gradeID: string;
  subjectID: string;
  chapterID: string;
  setGradeIDContext?: (gradeID: string) => void;
  setSubjectIDContext?: (subjectID: string) => void;
  setChapterIDContext?: (chapterID: string) => void;
}

// Initial Values
export const commonContext = {
  gradeID: '',
  subjectID: '',
  chapterID: '',
};

export const CommonContext = createContext<CommonCtx>(commonContext);
