import { memo } from 'react';

type Props = {
  verticalGap?: number;
};

const Spacer = ({ verticalGap }: Props) => {
  return (
    <div
      data-test_id="spacer_test"
      style={{ height: `${verticalGap ? verticalGap + 'px' : '20px'}` }}
      className="spacer"
    ></div>
  );
};

export default memo(Spacer);
