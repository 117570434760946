import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  deleteToken,
  deleteValueFromLocalStorage,
  getToken,
  getUserRole,
  getValueFromLocalStorage,
} from '../../../lib/Helper/helper';
import {
  getSuperAdminDetails,
  getTeacherDetails,
} from '../../../utils/loginApi';
import { AuthContext } from './AuthContext';

// AuthContextWrapper Props
type AuthContextWrapperProps = {
  children: React.ReactNode;
};

const AuthContextWrapper = ({ children }: AuthContextWrapperProps) => {
  const navigate = useNavigate();
  const [user, setUserData] = useState<object>({});
  const [role, setUserRole] = useState<'superAdmin' | 'admin' | 'teacher'>(
    'superAdmin',
  );
  const [isAuthenticate, setIsAuthenticate] = useState<boolean>(false);

  const setIsAuthenticateContext = (isAuth: boolean) =>
    setIsAuthenticate(isAuth);

  const setUserDataContext = (userObj: object) => setUserData(userObj);

  const setUserRoleContext = (role: 'superAdmin' | 'admin' | 'teacher') =>
    setUserRole(role);

  const setSuperAdminData = (
    data: object,
    role: 'superAdmin' | 'admin' | 'teacher',
  ) => {
    setUserData(data);
    setUserRoleContext(role);
  };

  const fetchInitialData = async () => {
    try {
      if (
        getValueFromLocalStorage('userID') &&
        getToken() &&
        getValueFromLocalStorage('role')
      ) {
        const userRole = getUserRole();

        const resp =
          userRole === 'superAdmin'
            ? await getSuperAdminDetails()
            : await getTeacherDetails();

        if (userRole === 'superAdmin') {
          setSuperAdminData(
            {
              ...resp.data.data[0],
              username: resp.data.data[0].email.split('@')[0],
            },
            'superAdmin',
          );
        } else {
          setSuperAdminData(resp.data.data[0], resp.data.data[0].role);
        }

        setIsAuthenticate(true);
      } else {
        deleteToken();
        deleteValueFromLocalStorage('garde');
        deleteValueFromLocalStorage('subject');
        deleteValueFromLocalStorage('chapter');
        deleteValueFromLocalStorage('userID');
        deleteValueFromLocalStorage('role');
      }
    } catch (error: any) {
      setIsAuthenticate(false);
      deleteToken();
      deleteValueFromLocalStorage('userID');
      toast.error(error?.response?.data?.message || error?.message || error);
    }
  };

  useEffect(() => {
    if (getValueFromLocalStorage('userID') && getToken())
      setIsAuthenticate(true);

    fetchInitialData();
  }, []);

  useEffect(() => {}, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticate,
        role,
        setIsAuthenticateContext,
        setUserDataContext,
        setUserRoleContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextWrapper;
