import React, { memo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/ContextWrapper/AuthContext/AuthContext';
import {
  deleteToken,
  deleteValueFromLocalStorage,
} from '../../lib/Helper/helper';

type Props = {};

const UserAvatar = (props: Props) => {
  const { setIsAuthenticateContext, setUserDataContext } =
    useContext(AuthContext);
  const navigate = useNavigate();

  const logout = async () => {
    setIsAuthenticateContext?.(false);
    setUserDataContext?.({});
    deleteToken();
    deleteValueFromLocalStorage('userID');
    // deleteValueFromLocalStorage("grade");
    // deleteValueFromLocalStorage("subject");
    // deleteValueFromLocalStorage("chapter");
    navigate('/login');
  };

  return (
    <div className="user_avatar dropdown">
      <img
        src="/assets/images/avatar.png"
        alt=""
        width="50"
        height="50"
        className="rounded-circle me-2"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      />
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <span className="dropdown-item" onClick={logout}>
            <i className="fa-solid fa-right-from-bracket" /> Log Out
          </span>
        </li>
      </ul>
    </div>
  );
};

export default memo(UserAvatar);
