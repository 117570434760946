import { createContext } from 'react';

export interface WindowDimensionContextCtx {
  windowDimensions: {
    width: number;
    height: number;
  };
  setWindowSize?: () => void;
}

// Initial Values
export const windowDimensionContext = {
  windowDimensions: {
    width: 0,
    height: 0,
  },
};

export const WindowDimensionContext = createContext<WindowDimensionContextCtx>(
  windowDimensionContext,
);
