import { AxiosError } from 'axios';
import {
  chapterDropdown,
  gradeDropdown,
  subjectDropdown,
} from '../../models/LessonModel';
import { getChaptersForDropdown } from '../../utils/chaptersApi';
import { getGradesForDropdown } from '../../utils/grades';
import { getSubjectsForDropdown } from '../../utils/subjects';

export const ValidateEmail = (mail: string) =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail) ? true : false;

export const getGradesSubjectsChapters = async () => {
  const [grdDrp, subDrp, chpDrp] = await Promise.all([
    getGradesForDropdown(),
    getSubjectsForDropdown(),
    getChaptersForDropdown(),
  ]);

  const gradesList: gradeDropdown[] = grdDrp.data.data;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const subjectList: subjectDropdown[] = subDrp.data.data.map((e: any) => {
    e.grade = e.grade._id;
    return e;
  });
  const chapterList: chapterDropdown[] = chpDrp.data.data;

  return { gradesList, subjectList, chapterList };
};

export const setToken = (token: string) =>
  window.localStorage.setItem('token', token);

export const setValueInLocalStorage = (label: string, value: string) =>
  window.localStorage.setItem(label, value);

export const getValueFromLocalStorage = (label: string) =>
  window.localStorage.getItem(label) || '';

export const deleteValueFromLocalStorage = (label: string) =>
  window.localStorage.removeItem(label);

export const getToken = () => window.localStorage.getItem('token');

export const deleteToken = () => window.localStorage.removeItem('token');

export const randomString = (length: number) => {
  const secret = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';

  for (let i = length; i > 0; --i)
    result += secret[Math.round(Math.random() * (secret.length - 1))];

  return result;
};

export const createEnCodedURL = (
  populate: Record<string, number | string> | Record<string, number | string>[],
) => encodeURI(JSON.stringify(populate));

export function swapArray<T>(
  arr: T[],
  firstSwap: number,
  secondSwap: number,
): T[] {
  const temp = arr[firstSwap];
  arr[firstSwap] = arr[secondSwap];
  arr[secondSwap] = temp;
  return arr;
}

interface errorModel {
  message?: string;
}

export const getErrorMessage = (err: unknown) => {
  const error: AxiosError = err as unknown as AxiosError;
  if (error?.response?.data) {
    const data = error.response.data as errorModel;
    return JSON.stringify(data?.message);
  }
  if (error?.message) return error?.message;
  return String(error);
};

export const getUserRole = () =>
  window.atob(window.atob(window.localStorage.getItem('role')!)) || '';

// export const randomString = (length: number) => {
//   const secret =
//     "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   var result = "";

//   for (var i = length; i > 0; --i)
//     result += secret[Math.round(Math.random() * (secret.length - 1))];

//   return result;
// };

export const generateRandomNumber = (length: number) => {
  const secret = '0123456789';
  let result = '';

  for (let i = length; i > 0; --i)
    result += secret[Math.round(Math.random() * (secret.length - 1))];

  return result;
};

export const daysInThisMonth = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

export const addDays = (date: Date, days: number) =>
  new Date(date.getTime() + days * 24 * 60 * 60 * 1000);

export const minusDays = (date: Date, days: number) =>
  new Date(date.getTime() - days * 24 * 60 * 60 * 1000);

export const createDateRanges = (
  numOfDays: number,
  date: Date,
): Array<string> =>
  [...Array(numOfDays)].map((e, i) => {
    const d = date ? new Date(date) : new Date();
    return new Date(d.setDate(d.getDate() - i)).toISOString().split('T')[0];
  });

export const differenceBtw2Dates = (dateObj: {
  startDate: string;
  endDate: string;
}): number => {
  const { startDate, endDate } = dateObj;

  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  const Difference_In_Time = date2.getTime() - date1.getTime();

  return Number((Difference_In_Time / (1000 * 3600 * 24)).toFixed()) + 1;
};

export const groupByData = function <T extends Record<string, string>>(
  arr: T[],
  key: string,
) {
  return arr.reduce(function (groups: Record<string, T[]>, item: T) {
    (groups[item[key]] = groups[item[key]] || []).push(item);
    return groups;
  }, {});
};
