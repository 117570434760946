import axios from 'axios';
import { getToken, getUserRole } from '../lib/Helper/helper';

export const subdomain = window.location.hostname
  .split('.')
  .slice(0, -2)
  .shift();

if (!subdomain) {
  window.location.href = '/404.html';
}

export const oldBackend = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? `https://${subdomain}.${process.env.REACT_APP_CORRSY_BACKEND_PRODUCTION}`
      : `http://${subdomain}.${process.env.REACT_APP_CORRSY_OLD_BACKEND_LOCAL}`,
});

export const newBackend = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? `https://${subdomain}-lessonapi.${process.env.REACT_APP_CORRSY_BACKEND_PRODUCTION}`
      : `http://${subdomain}.${process.env.REACT_APP_CORRSY_NEW_BACKEND_LOCAL}`,
});

oldBackend.interceptors.request.use(
  (config) => {
    const token: string = getToken()!;
    if (token) {
      config.headers = { Authorization: `bearer ${token}` };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

newBackend.interceptors.request.use(
  (config) => {
    const token: string = getToken()!;
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        role: getUserRole(),
      };
    }
    return config;
  },
  (error) => Promise.reject(error),
);
