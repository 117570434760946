import { createContext } from 'react';
export interface AuthCtx {
  user: any;
  isAuthenticate: boolean;
  role: 'superAdmin' | 'admin' | 'teacher';
  setUserDataContext?: (user: object) => void;
  setIsAuthenticateContext?: (isAuth: boolean) => void;
  setUserRoleContext?: (role: 'superAdmin' | 'admin' | 'teacher') => void;
}

const getRole = (): 'superAdmin' | 'admin' | 'teacher' => 'superAdmin';

// Initial Values
export const authContext = {
  user: {},
  isAuthenticate: false,
  role: getRole(),
};

export const AuthContext = createContext<AuthCtx>(authContext);
