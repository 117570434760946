import React from 'react';
import Header from '../components/Common/Header';
import Spacer from '../components/Common/Spacer';
import Sidebar from '../components/Sidebar/Sidebar';

type Props = {
  children: React.ReactNode;
  heading: string;
  isHeadingShow?: boolean;
};

const Layout = ({ children, heading, isHeadingShow = true }: Props) => {
  return (
    <Sidebar>
      {isHeadingShow && <Header heading={heading} />}
      <Spacer />
      {children}
      <Spacer verticalGap={50} />
    </Sidebar>
  );
};

export default Layout;
