import React, { useEffect, useState } from 'react';
import { CommonContext } from './CommonContext';

type CommonContextWrapperProps = {
  children: React.ReactNode;
};

const CommonContextWrapper = ({ children }: CommonContextWrapperProps) => {
  const [gradeID, setGradeID] = useState<string>('');
  const [subjectID, setSubjectID] = useState<string>('');
  const [chapterID, setChapterID] = useState<string>('');

  const setGradeIDContext = (selectedGradeID: string) =>
    setGradeID(selectedGradeID);

  const setSubjectIDContext = (selectedSubjectID: string) =>
    setSubjectID(selectedSubjectID);

  const setChapterIDContext = (selectedChapterID: string) =>
    setChapterID(selectedChapterID);

  return (
    <CommonContext.Provider
      value={{
        gradeID,
        subjectID,
        chapterID,
        setGradeIDContext,
        setSubjectIDContext,
        setChapterIDContext,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export default CommonContextWrapper;
