// react imports
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import { lazy, Suspense, useContext, useEffect } from 'react';
import { AuthContext } from './context/ContextWrapper/AuthContext/AuthContext';
import Layout from './layouts/Layout';
import { Toaster } from 'react-hot-toast';
import { allRoutes } from './models/RoutesModel';

// component imports
const ErrorPage = lazy(() => import('./components/ErrorPage/ErrorPage'));

interface IAppProps {}

const App = (props: IAppProps) => {
  const { isAuthenticate, role } = useContext(AuthContext);

  const renderLayout = (Component: any) => (props: any) => (
    <Layout heading={props?.heading} isHeadingShow={props?.isHeadingShow}>
      <Component {...props} />
    </Layout>
  );

  const protectedRoutes = () =>
    allRoutes
      .filter((e) => e.isProtected && e.access.includes(role))
      .map((e, i) => (
        <Route
          key={`route_${i}`}
          path={e.path}
          element={renderLayout(e.Component)({
            heading: e.heading,
            isHeadingShow: e.isHeadingShow,
          })}
        />
      ));

  const normalRoutes = () =>
    allRoutes
      .filter((e) => !e.isProtected)
      .map((e, i) => (
        <Route key={`route_${i}`} path={e.path} element={<e.Component />} />
      ));

  const renderLoader = () => <h1>Loader ....</h1>;

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 2000,
        }}
      />
      <Suspense fallback={renderLoader()}>
        <Routes>
          {isAuthenticate ? protectedRoutes() : normalRoutes()}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
