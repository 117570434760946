import { API_ENDPOINTS } from '../api/API_ENDPOINTS';
import { newBackend, oldBackend } from '../api/request';
import { getValueFromLocalStorage } from '../lib/Helper/helper';

const urlForGetSubjectsForDropdown: string =
  API_ENDPOINTS.SUBJECTS.GET_SUBJECTS_DROPDOWN;

const urlForGetTeacherAssignSubjects: string =
  API_ENDPOINTS.SUBJECTS.TEACHER_SUBJECTS;

const urlForGetAllSubjects: string = API_ENDPOINTS.SUBJECTS.GET_ALL_SUBJECT;

const populateData = [
  {
    path: 'gradesList',
    select: 'grade isActive',
  },
  {
    path: 'subjectsList',
    select: 'Subject_name isActive grade',
  },
];

const populate: string = encodeURI(JSON.stringify(populateData));

export const getSubjectsForDropdown = async () =>
  await oldBackend({
    method: 'GET',
    url: urlForGetSubjectsForDropdown,
  });

export const getAllSubjects = async () =>
  await newBackend({
    method: 'GET',
    url: urlForGetAllSubjects,
  });

export const getAssignSubjects = async () =>
  await oldBackend({
    method: 'GET',
    url: `${urlForGetTeacherAssignSubjects}?teacher_id=${getValueFromLocalStorage(
      'userID',
    )}&populate=${populate}`,
  });
